<template>
    <page-section class="page-section--xs pb-0" v-if="relateds.length && enabled">
        <tit-wrap-x-small title="추가 제품 선택" />
        <v-select return-object placeholder="추가제품선택" dense outlined hide-details disable-lookup :items="relateds" v-bind="{ itemText, itemDisabled }" @change="emit">
            <!-- 개발확인: 추가제품-->
            <template #item>
                <div class="py-12px">
                    <v-row align="center" class="row--xs ma-md-n8px">
                        <v-col cols="auto" class="pa-md-8px">
                            <v-img src="/images/test-img.jpg" width="60" class="rounded" />
                        </v-col>
                        <v-col class="pa-md-8px">
                            <v-sheet>

                                <div class="txt txt--sm txt--dark font-weight-medium mb-12px">
                                    제품명
                                </div>
                                <div class="d-flex align-center font-size-20 font-size-md-28">
                                    <template>
                                        <strong class="font-size-16 d-inline-flex align-center">
                                            123
                                            <small class="font-size-14 font-weight-regular pl-2px">원</small>
                                        </strong>
                                    </template>
                                    <template>
                                        <span class="font-size-14 text-decoration-line-through grey--text text--lighten-1 ml-8px">
                                            456
                                        </span>
                                    </template>
                                </div>

                            </v-sheet>
                        </v-col>
                    </v-row>
                </div>
            </template>
        </v-select>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";

export default {
    components: {
        PageSection,
        TitWrapXSmall,
    },
    props: {
        value: { type: Array, default: () => [] }, // carts
        product: { type: Object, default: null },
    },
    computed: {
        relateds() {
            return (this.product?.relateds || []).filter(({ enabled }) => enabled);
        },
        enabled() {
            return this.product?.relatedsConf?.enabled || false;
        },
    },
    methods: {
        emit(related) {
            if (related && !this.value.find((cart) => cart._related == related._id)) {
                const cart = {
                    _product: this.product._id,
                    _related: related._id,
                    product: this.product,
                    related,
                    name: related.name,
                    price: related.price,
                    salePrice: related.salePrice,
                    discountPrice: related.discountPrice,
                    stock: related.stock,
                    amount: 1,
                };
                this.$emit("input", [...this.value, cart]);
            }
        },
        itemText({ name, salePrice, stock }) {
            let text = this.$decode__productOptionName(name);
            if (stock) {
                if (salePrice) {
                    if (0 <= salePrice) text += ` (+${salePrice.format()}원)`;
                    else text += ` (${salePrice.format()}원)`;
                }
            } else text += " (품절)";
            return text;
        },
        itemDisabled({ stock }) {
            return stock == 0;
        },
    },
};
</script>
