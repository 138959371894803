<template>
    <div>
        <div class="py-sm-24px py-16px">
            <slot />
        </div>
        <v-divider v-if="!hideDivider" />
    </div>
</template>

<script>
export default {
    props: {
        hideDivider: { type: Boolean, default: false },
    },
};
</script>

<style>
</style>