var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return 1 < _vm.items.length ? _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": "배송선택"
    }
  }), _c('v-select', _vm._b({
    attrs: {
      "placeholder": "선택",
      "return-object": "",
      "item-value": "_id"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.shippingOption,
      callback: function ($$v) {
        _vm.shippingOption = $$v;
      },
      expression: "shippingOption"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input, {
    items: _vm.items
  }), false))], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }