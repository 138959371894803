var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.relateds.length && _vm.enabled ? _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": "추가 제품 선택"
    }
  }), _c('v-select', _vm._b({
    attrs: {
      "return-object": "",
      "placeholder": "추가제품선택",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "disable-lookup": "",
      "items": _vm.relateds
    },
    on: {
      "change": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function () {
        return [_c('div', {
          staticClass: "py-12px"
        }, [_c('v-row', {
          staticClass: "row--xs ma-md-n8px",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-md-8px",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-img', {
          staticClass: "rounded",
          attrs: {
            "src": "/images/test-img.jpg",
            "width": "60"
          }
        })], 1), _c('v-col', {
          staticClass: "pa-md-8px"
        }, [_c('v-sheet', [_c('div', {
          staticClass: "txt txt--sm txt--dark font-weight-medium mb-12px"
        }, [_vm._v(" 제품명 ")]), _c('div', {
          staticClass: "d-flex align-center font-size-20 font-size-md-28"
        }, [[_c('strong', {
          staticClass: "font-size-16 d-inline-flex align-center"
        }, [_vm._v(" 123 "), _c('small', {
          staticClass: "font-size-14 font-weight-regular pl-2px"
        }, [_vm._v("원")])])], [_c('span', {
          staticClass: "font-size-14 text-decoration-line-through grey--text text--lighten-1 ml-8px"
        }, [_vm._v(" 456 ")])]], 2)])], 1)], 1)], 1)];
      },
      proxy: true
    }], null, false, 470465363)
  }, 'v-select', {
    itemText: _vm.itemText,
    itemDisabled: _vm.itemDisabled
  }, false))], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }