var render = function render(){
  var _vm$product, _vm$product2, _vm$product2$optionsC, _vm$product3, _vm$product3$optionsC;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.shows ? _c('div', [((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.type) == _vm.PRODUCT_TYPES.NORMAL_PRODUCT.value ? [_c('options-colors', {
    attrs: {
      "criteria": (_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : (_vm$product2$optionsC = _vm$product2.optionsConf) === null || _vm$product2$optionsC === void 0 ? void 0 : _vm$product2$optionsC.criteria
    },
    on: {
      "input": _vm.pushOption
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }), _c('options-others', {
    attrs: {
      "criteria": (_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : (_vm$product3$optionsC = _vm$product3.optionsConf) === null || _vm$product3$optionsC === void 0 ? void 0 : _vm$product3$optionsC.criteria
    },
    on: {
      "input": _vm.pushOption
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })] : _vm._e(), _c('options-variation', _vm._g(_vm._b({}, 'options-variation', {
    value: _vm.value,
    product: _vm.product
  }, false), {
    push: _vm.push,
    emit: _vm.emit
  })), _c('options-relateds', _vm._b({
    on: {
      "input": function (carts) {
        return _vm.$emit('input', carts);
      }
    }
  }, 'options-relateds', {
    value: _vm.value,
    product: _vm.product
  }, false))], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }