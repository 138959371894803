<template>
    <div v-if="shows">
        <template v-if="product?.type == PRODUCT_TYPES.NORMAL_PRODUCT.value">
            <options-colors v-model="selected" :criteria="product?.optionsConf?.criteria" @input="pushOption" />
            <options-others v-model="selected" :criteria="product?.optionsConf?.criteria" @input="pushOption" />
        </template>
        <!-- <options-supplies v-bind="{ value, product }" @input="(carts) => $emit('input', carts)" /> -->
        <options-variation v-bind="{ value, product }" v-on="{ push, emit }" />
        <options-relateds v-bind="{ value, product }" @input="(carts) => $emit('input', carts)" />
    </div>
</template>

<script>
import { initProduct, PRODUCT_TYPES } from "@/assets/variables";

import InfoSection from "@/components/client/shop/products/view/info/info-section.vue";
import OptionsColors from "./options/options-colors.vue";
import OptionsOthers from "./options/options-others.vue";
// import OptionsSupplies from "./options/options-supplies.vue";
import OptionsRelateds from "./options/options-relateds.vue";
import OptionsVariation from "./options/options-variation.vue";

export default {
    components: {
        InfoSection,
        OptionsColors,
        OptionsOthers,
        // OptionsSupplies,
        OptionsRelateds,
        OptionsVariation,
    },
    props: {
        value: { type: Array, default: () => [] }, // carts
        product: { type: Object, default: initProduct },
    },
    data: () => ({
        selected: {},

        PRODUCT_TYPES,
    }),
    computed: {
        shows() {
            const isOptionEnabled = this.product?.optionsConf?.enabled;
            const isSupplyEnabled = this.product?.suppliesEnabled;
            const isVarietyBundle = this.product?.type == PRODUCT_TYPES.VARIETY_BUNDLE.value;

            return isOptionEnabled || isSupplyEnabled || isVarietyBundle;
        },
        options() {
            return this.product?.options || [];
        },
    },
    methods: {
        pushOption() {
            const keys = this.product?.optionsConf?.criteria?.map?.(({ name }) => name);

            const hasItem = !keys.some((key) => !this.selected[key]);
            if (hasItem) {
                const optionName = keys.map((key) => `${encodeURIComponent(key)}: ${encodeURIComponent(this.selected[key])}`).join(" / ");

                const option = this.options.find((option) => option.name == optionName);
                if (option) {
                    if (option.stock < 1) {
                        alert("재고가 없는 상품입니다.");
                        return;
                    }

                    const index = this.value.findIndex(({ _option }) => _option == option?._id);
                    if (-1 < index) {
                        const carts = [...this.value];
                        carts[index].amount += 1;
                        this.emit(carts);
                    } else {
                        const cart = {
                            _product: this.product?._id,
                            _option: option?._id,
                            name: option?.name,
                            price: option?.price,
                            salePrice: option?.salePrice,
                            discountPrice: option?.discountPrice,
                            stock: option?.stock,
                            stockEnabled: option?.stockEnabled,
                            amount: 1,
                        };
                        this.push(cart);
                    }
                }
            }
        },
        push(cart) {
            this.$emit("input", [...this.value, cart]);
        },
        emit(carts) {
            this.$emit("input", carts);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .page-section {
        &:first-child {
            padding-top: 0;
        }
    }
}
</style>
